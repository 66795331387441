<template>
  <div class="auth">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card shadow">
            <div class="card-body" v-if="! user_registered">
              <h4 class="card-title bordered">TRADESK</h4>
              <h5 class="mb-4 text-center text-secondary">Create an account</h5>
              <div class="alert alert-danger" v-if="has_errors">The data provided is invalid</div>
              <form @submit.prevent="register">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" placeholder="Name" id="name" :class="`form-control ${invalid_name ? 'is-invalid' : ''}`" v-model="user.name">
                  <div class="invalid-feedback" v-if="invalid_name">{{ errors_422.name[0] }}</div>
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="text" placeholder="Email" id="email" :class="`form-control ${invalid_email ? 'is-invalid' : ''}`" v-model="user.email">
                  <div class="invalid-feedback" v-if="invalid_email">{{ errors_422.email[0] }}</div>
                </div>
                <div class="form-group">
                  <label for="password">Password</label>
                  <input type="password" placeholder="******" id="password" :class="`form-control ${invalid_password ? 'is-invalid' : ''}`" v-model="user.password">
                  <div class="invalid-feedback" v-if="invalid_password">{{ errors_422.password[0] }}</div>
                </div>
                <div class="form-group">
                  <label for="retype-password">Re-type Password</label>
                  <input type="password" placeholder="******" id="retype-password" class="form-control" v-model="user.password_confirmation">
                </div>
                <div class="form-group text-right">
                  <button class="btn btn-theme"><i class="lni-lock mr-2"></i> Create Account</button>
                </div>
              </form>
              <p><router-link :to="{ name: 'login' }"><i class="lni-user mr-3"></i> Have an account? Login.</router-link></p>
            </div>
            <div class="card-body text-center" v-if="user_registered">
              <h4 class="card-title bordered">TRADESK</h4>
              <p>Your account has been created. Click on the link in your email to activate account.</p>
              <router-link :to="{ name: 'login' }" class="btn btn-theme text-white"><i class="lni-lock mr-2"></i> Go To Login</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
      },
      errors_422: {
        email: [],
        name: [],
        password: []
      },
      has_errors: false,
      user_registered: false
    }
  },

  methods: {
    register() {
      this.$loader.start()
      this.has_errors = false
      this.user_registered = false

      this.errors_422 = {
        email: [],
        name: [],
        password: []
      }

      this.$axios.post('/api/v1/register', this.user).then(() => {
        this.user_registered = true
        this.$loader.stop()
      }).catch(error => {
        if(error) {
          let res = error.response

          if(res.status == 422) {
            this.errors_422 = res.data.errors
          }
        }
        
        this.has_errors = true
        document.documentElement.scrollTop = 0;
        this.$loader.stop()
      })
    }
  },

  computed: {
    invalid_name() {
      if(this.errors_422.name) {
        return this.errors_422.name.length > 0
      }

      return 0
    },

    invalid_email() {
      if(this.errors_422.email) {
        return this.errors_422.email.length > 0
      }

      return 0
    },

    invalid_password() {
      if(this.errors_422.password) {
        return this.errors_422.password.length > 0
      }

      return 0
    }
  },

  watch: {
    user() {
      this.errors_422 = {
        email: [],
        name: [],
        password: []
      }
    }
  }
}
</script>
